<template>
	<div>
		<table id="holiday" class="table-sticky">
			<thead>
				<tr>
					<th class="child1">{{$t("common.name")}}</th>
					<th class="child2">{{$t("common.sdate")}}</th>
					<th class="child3">{{$t("common.edate")}}</th>
					<th class="child4">{{$t("common.reasons")}}</th>
					<th class="child5">{{$t("common.status")}}</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="absence in absences" :key="'abs-' + absence.id" :id="'abs-' + absence.id">
					<td class="child1 text-center">
							{{ absence.worker.username === 'general' ? $t('common.salon') : absence.worker.fname + " " + ( absence.worker.lname || "" ).substr( 0, 1 ).toUpperCase() }}
					</td>
					<td class="child2 text-center">
						{{ ( absence.start.getHours() == 0 && absence.start.getMinutes() == 0 ) ? $d( absence.start, "longonlydate" ) : $d( absence.start, "long" ) }}
					</td>
					<td class="child3 text-center">
						{{ ( absence.end.getHours() == 0 && absence.end.getMinutes() == 0 ) ?
							$d( $dayjs(absence.end).add(-1, 'day').toDate(), "longonlydate" ) :
							$d( absence.end, "long" ) }}
					</td>
					<td class="child4 text-center">{{ absence.message }}</td>
					<td class="child5 text-center">
						<template v-if="absence.status === null">
							<StatusButton id="validate" type="outline-success" alert="modal" @click="setStatus(absence, 'validated', $event)"><i class="fas fa-check"></i></StatusButton>
							<StatusButton id="forbidden" type="outline-danger" alert="modal" @click="setStatus(absence, 'refused', $event)"><i class="fas fa-times"></i></StatusButton>
						</template>
						<template v-else>
							{{ $t( `data.absences['${absence.status || ""}']` ) }}
							<span v-if="absence.status == 'validated' && $dayjs(absence.start).isAfter($dayjs())" class="delete" @click="showDeleteConfirm=absence"><i class="fas fa-times"></i></span>
						</template>
					</td>
				</tr>
			</tbody>
		</table>
		<infinite-loading ref="loader" @infinite="infiniteHandler">
			<template v-slot:no-results>{{ $t('infiniteloader.noResult') }}</template>
			<template v-slot:no-more><span /></template>
			<template v-slot:error>{{ $t('infiniteloader.error') }}</template>
		</infinite-loading>
		<Confirm v-if="showDeleteConfirm" ref="deleteConfirm">
			{{ $t('vacation.confirmdelete') }}
		</Confirm>
	</div>
</template>

<script>
	import dayjs from "dayjs"
	import Modal from "../../components/Modal.vue"
	import Confirm from "../../modals/Confirm.vue"
	import StatusButton from "../../components/StatusButton.vue"
	import InfiniteLoading from "vue-infinite-loading"
	
	export default {
		components: {
			Modal,
			Confirm,
			StatusButton,
			InfiniteLoading
		},
		data() {
			return {
				absences : [],
				showDeleteConfirm: false
			}
		},
		watch: {
			showDeleteConfirm( value ) {
				if ( value !== false ) {
					this.$nextTick( () => {
						this.$refs.deleteConfirm.$on( "confirm", () => {
							this.$refs.deleteConfirm.$off( 'confirm' )
							this.$refs.deleteConfirm.$off( 'close' )
							this.deleteAbsence( value )
							this.showDeleteConfirm = false
						})
						this.$refs.deleteConfirm.$on( "close", () => {
							this.$refs.deleteConfirm.$off( 'confirm' )
							this.$refs.deleteConfirm.$off( 'close' )
							this.showDeleteConfirm = false
						})
					})
				}
			}
		},
		methods: {
			setStatus( absence, status, callback ) {
				this.$api.absence.patch( absence.id, { status: status } ).then( response => {
					callback( true )
				}).catch( error => {
					callback( false, error.response.data.error )
					console.log( error.response.data.error )
				})
				callback( true )
			},
			infiniteHandler( infiniteState ) {
				this.getmore().then( newEntries => {
					if ( !newEntries.length ) {
						infiniteState.complete()
					} else {
						infiniteState.loaded()
					}
				})
			},
			async getmore() {
				const response = await this.$api.absences.get({
					"{max}": 10,
					"{sort}": "desc:start",
					"start[lt]": ( this.absences.length > 0 ? dayjs(this.absences[this.absences.length - 1].start).second(0).millisecond(0).toISOString() : undefined )
				}).catch(error => console.log(error))

				const absences = response ? response.data : []
				for (let i = 0; i < absences.length; i++) {
					absences[i].worker = this.$store.getters.getWorker( absences[i].worker )
					absences[i].start = new Date( absences[i].start )
					absences[i].end = new Date( absences[i].end )
				}

				this.absences.push(...absences)
				return absences
			},
			update() {
				this.absences = []
				this.$nextTick( () => {
					this.$refs.loader.stateChanger.reset()
				})
			},
			deleteAbsence() {
				console.log( this.showDeleteConfirm )
				this.$api.absence.delete( this.showDeleteConfirm.id ).then( response => {
					this.showDeleteConfirm = false
				}).catch( error => {
					console.log( error )
				//	this.showDeleteConfirm.callback( false, error.response.data.error )
					this.showDeleteConfirm = false
				})
			},
		},
		created() {
			this.$api.absences.watch( event => {
				if ( event.method === "DELETE" ) {
					for ( let i = 0; i < event.data.length; i++ ) {
						let found = this.absences.findIndex( abs => abs.id === event.data[i].id )
						if ( found >= 0 ) {
							this.absences.splice( found, 1 )
						}
					}
				} else if ( event.method === "PATCH" ) {
					for ( let i = 0; i < event.data.length; i++ ) {
						let found = this.absences.find( abs => abs.id === event.data[i].id );
						if ( found ) {
							Object.assign( found, event.data[i] )
							found.start = new Date( found.start )
							found.end = new Date( found.end )
						}
					}
				} else if ( event.method === "POST" ) {
					for ( let i = 0; i < event.data.length; i++ ) {
						event.data[i].start = new Date( event.data[i].start )
						event.data[i].end = new Date( event.data[i].end )
						for ( let j = 0; j < this.absences.length; j++ ) {
							if ( daysjs(event.data[i].start).isSameOrAfter( this.absences[Math.max(j-1, 0)].start ) && daysjs(event.data[i].start).isSameOrBefore( this.absences[j].start ) ) {
								this.absences.splice( j, 0, event.data[i] )
								break
							}
						}
					}
				}
			})
		}
	}
</script>
